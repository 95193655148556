<!--  -->
<template>
  <div class="outerWrapper">
    <div class="navBar">
      <van-nav-bar :title="navbarTitle" left-arrow class="navbarWrapper">
        <template #left>
          <van-icon
            @click="onClickLeft"
            name="arrow-left"
            size=".9rem"
            color="white"
          />
        </template>
        <template #right>
          <van-icon
            name="wap-nav"
            size="18"
            @click="handleClickRightMenu"
            color="white"
          />
        </template>
      </van-nav-bar>
      <van-popup v-model="show" position="right" :style="{ height: '100%' }">
        <van-cell-group>
          <van-cell value="退出登录" @click="handleLogout" />
        </van-cell-group>
      </van-popup>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getCasLogoutUrl } from "@/utils/casUrl";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      show: false
    };
  },
  //监听属性 类似于data概念
  computed: {
    navbarTitle() {
      return this.$store.state.routerInfo.navBarTitle;
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleLogout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("sid");
      let logoutUrl = getCasLogoutUrl();
      console.log(logoutUrl);
      window.location.href = logoutUrl;
      this.show = false;
    },
    handleClickRightMenu() {
      console.log("right");
      this.show = true;
    },
    onClickLeft() {
      console.log(this.$route);
      this.$router.go(-1);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
</style>
