function getCasLoginUrl() {
  let casUrlConfig = {
    development: "https://cas-test.sustech.edu.cn/cas/login?service=",
    test: "https://cas-test.sustech.edu.cn/cas/login?service=",
    production: "https://cas.sustech.edu.cn/cas/login?service="
  };
  let mainUrl = location.href.replace("login", "redirect");
  let currentCasURl = casUrlConfig[process.env.NODE_ENV];
  console.log(process.env.NODE_ENV, currentCasURl, currentCasURl + mainUrl);
  let ultimateUrl = currentCasURl + mainUrl;
  return ultimateUrl;
}

function getCasLogoutUrl() {
  let casUrlConfig = {
    development: "https://cas-test.sustech.edu.cn/cas/logout",
    test: "https://cas-test.sustech.edu.cn/cas/logout",
    production: "https://cas.sustech.edu.cn/cas/logout"
  };
  let currentCasURl = casUrlConfig[process.env.NODE_ENV];
  return currentCasURl;
}

export { getCasLoginUrl, getCasLogoutUrl };
